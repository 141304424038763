export enum INPUT_TYPE {
  TEXT,
  EMAIL,
  PASSWORD,
  TEXTAREA,
  AUTOCOMPLETE,
  DROPDOWN,
  CURRENCY,
  SLIDER,
  DATEPICKER,
  SWITCH,
  CHIPS
}
