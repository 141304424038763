import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './modules/auth/services/auth.guard';
import { Urls } from './_constants/Urls';
import { NoAuthGuard } from './modules/auth/services/no-auath.guard';

export const routes: Routes = [
  // {
  //   path: 'auth',
  //   loadChildren: () =>
  //     import('./modules/auth/auth.module').then((m) => m.AuthModule),
  // },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: Urls.APPS,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./_internal/layout/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: `${Urls.APPS}/${Urls.HOME}`,
    canActivate: [NoAuthGuard],
    loadChildren: () =>
      import('./modules/apps/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: '',
    redirectTo: `/${Urls.APPS}/${Urls.HOME}`,
    pathMatch: 'full',
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
