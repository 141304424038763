import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterText',
  standalone: true
})
export class FilterTextPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }

    if (!searchText) {
      return items;
    }

    searchText = searchText.toLocaleLowerCase();

    return items.filter((item: any) => {
      return item.name.toLocaleLowerCase().includes(searchText);
    })
  }

}
