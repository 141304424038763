<label class="profile-uploader-container" for="image-uploader-input-file">
  <div 
    class="image-container" 
    [ngStyle]="{
      'backgroundImage': 'url(' + file +')',
      'width': width,
      'height': height
    }" 
    *ngIf="file; else defaultProfile;">
  </div>
  <div class="icon-wrapper">
    <div class="delete-icon ms-2" (click)="removePhoto($event)" *ngIf="!!file">
      <app-keenicon name="trash" class="fs-3 text-danger me-4"></app-keenicon>
    </div>
  </div>

  <ng-template #defaultProfile>
      <img
        class="image-container" 
        [ngStyle]="{
          'width': width,
          'height': height
        }" 
        src="assets/media/avatars/blank.png">
  </ng-template>
  <input type="file" id="image-uploader-input-file" accept="image/*" (change)="onFileChange($event)">
</label>
