import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable, catchError, throwError } from "rxjs";
import { Urls } from "src/app/_constants/Urls";
import { AuthService } from "src/app/modules/auth";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  
  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(res => {
        if ([401].includes(res.status)) {
          this.authService.logout(); 
          this.toastrService.error(res.error.detail, res.statusText);
          this.router.navigate([`${Urls.APPS}/${Urls.HOME}`]);
        } else if (res.status === 404) {
          this.toastrService.error('Request not found');
          this.router.navigate([`${Urls.APPS}/${Urls.HOME}`]);
        } else if (res.status === 403) {
          this.toastrService.error(res.error);
        } else if (res.status === 0) {
          this.toastrService.error(res.message);
        } else {
          const errors = res.error.error;

          if (errors) {
            Object.keys(errors).forEach(key => {
              const errorMessage = errors[key];
              if (Array.isArray(errorMessage)) {
                if (errorMessage.length) {
                  if (errorMessage.length > 1) {
                    let listItems: string[] = [];
                    errorMessage.forEach(message => {
                      listItems.push(`<li>${message}</li>`)
                    });
                    this.toastrService.error(`<ul>${listItems.join('')}</ul>`, `INVALID ${key.toUpperCase()}!`, {
                      enableHtml: true
                    });
                  } else {
                    this.toastrService.error(errorMessage[0], `INVALID ${key.toUpperCase()}!`);
                  }
                }
              } else {
                this.toastrService.error(errorMessage);
              }
            });
          }
          
        }
        const error = res.error.message || res.statusText;
        return throwError(error);
      })
    )
  }

}
