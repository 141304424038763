import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAMUserDetailsDto } from '../_shared/models/iam-user-details.dto';
import { AccountProfileDto } from '../_shared/models/account-profile.dto';
import { AccountProfile } from '../_shared/models/account-profile.model';
import { ResetPasswordPayload } from '../_shared/models/payloads/reset-password-payload.dto';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private readonly apiUrlV1;
  private readonly iamUserDetailsUrl = 'iam/iamuserdetails/me';
  private readonly iamAccountsUrl = 'iam/accounts';

  private _accountDetails: AccountProfile;

  constructor(private httpClient: HttpClient) { 
    this.apiUrlV1 = environment.apiStudioUrl;
  }

  getIamUserDetails(): Observable<IAMUserDetailsDto> {
    return this.httpClient.get<IAMUserDetailsDto>(`${this.apiUrlV1}/${this.iamUserDetailsUrl}/`);
  }

  getAccountsProfile(): Observable<AccountProfileDto> {
    return this.httpClient.get<AccountProfileDto>(`${this.apiUrlV1}/${this.iamAccountsUrl}/profile/`);
  }

  updateAccountProfile(firstName: string, lastName: string, userName: string): Observable<any> {
    return this.httpClient.put<any>(`${this.apiUrlV1}/${this.iamAccountsUrl}/profile/`, 
      { 
        first_name: firstName, 
        last_name: lastName,
        username: userName,
      }
    );
  }

  requestResetPasswordLink(email: string) {
    return this.httpClient.post(`${this.apiUrlV1}/${this.iamAccountsUrl}/send-reset-password-link/`, { login: email });
  }

  resetPassword(body: ResetPasswordPayload) {
    return this.httpClient.post(`${this.apiUrlV1}/${this.iamAccountsUrl}/reset-password/`, body);
  }

  getUserDetailsAndProfile(): Observable<AccountProfile> {
    return combineLatest([
      this.getIamUserDetails(),
      this.getAccountsProfile()
    ]).pipe(
      map(([userDetails, accountProfile]) =>  new AccountProfile(accountProfile, userDetails)),
      tap((value) => {
        this._accountDetails = value;
      })
    );
  }

  get accountDetails() {
    return this._accountDetails;
  }
}
