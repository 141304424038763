import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CustomControl } from '../../models/custom-form.model';
import { INPUT_TYPE } from 'src/app/_constants/InputTypes';
import { debounceTime, map } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrl: './custom-input.component.scss'
})
export class CustomInputComponent implements OnInit {
  @ViewChild('defaultOption') defaultOption: ElementRef<HTMLElement>;
  @Input() control: CustomControl;
  @Input() type: INPUT_TYPE;
  @Input() listValue: any[] = [];
  @Input() defaultValue: any;
  @Input() sliderMin: number = 0;
  @Input() sliderStep: number = 0;
  @Input() sliderMax: number = 0;
  @Input() inputType: string = 'text';
  @Input() multiSelect: boolean = false;
  @Input() withLabel: boolean = true;

  @Output() search: EventEmitter<any> = new EventEmitter<any> ();
  
  inputTypes = INPUT_TYPE;
  loadingResults: boolean;
  searching: boolean;

  chipListsItem: string[] = []
  searchText = '';

  ngOnInit(): void {

    if (this.type === INPUT_TYPE.CHIPS) {
      this.chipListsItem = this.listValue.map(lv => lv.name);
      if (this.defaultValue) this.control.patchValue(this.defaultValue.map((dv: any) => dv.name).join(','))
    }
    
    if (!this.type) {
      this.type = this.control.type ? this.control.type : INPUT_TYPE.TEXT;
    }
    if (this.type === INPUT_TYPE.AUTOCOMPLETE) {
      this.handleLocalValueChange();
      this.handleGlobalValueChange();
    }  else if (this.type === INPUT_TYPE.DROPDOWN) {
      this.handleDropdownDefaultValues();
    }
  }

  handleLocalValueChange(): void {
    this.control.auxControl.valueChanges
      .pipe(
        map((val) => {
          this.loadingResults = true;
          return val;
        }),
        debounceTime(500)
      ).subscribe((value) => {
        this.displayList(value);
      });
  }

  handleGlobalValueChange(): void {
    if (this.control.value) {
      this.control.auxControl.patchValue(this.extractValueToDisplay(this.control.value), { emitEvent: false });
      this.checkDisabledStatus();
    }

    this.control.valueChanges.subscribe((value) => {
      if (value) {
        this.control.auxControl.patchValue(this.extractValueToDisplay(value), { emitEvent: false });
        this.checkDisabledStatus();
      } else {
        this.checkDisabledStatus();
        this.control.auxControl.patchValue(null, { emitEvent: false });
      }
    })
  }

  extractValueToDisplay(value: any): string {
    return value.name
      .split(' ')
      .map((word: any) => {
        const wordArray = word.split('');
        const capital = wordArray[0];
        wordArray.splice(0, 1);
        return capital + wordArray.join('').toLowerCase();
      })
      .join(' ');
  }

  checkDisabledStatus(): void {
    if (this.control.disabled) {
      this.control.auxControl.disable({ emitEvent: false });
    } else if (this.control.auxControl.disabled) {
      this.control.auxControl.enable({ emitEvent: false });
    }
  }

  displayList(value: string = ''): void {
    !this.loadingResults && (this.loadingResults = true);
    this.search.emit(value);
    this.searching = true;
  }

  onDropdownChange(event: any, selectedValue: any, multiSelect = true): void {
    if (multiSelect) {
      if (event.checked) {
        if (selectedValue === 'selectAll') {
          this.listValue.map(d => d.select());
        } else {
          selectedValue.select();
        }
      } else {
        if (selectedValue === 'selectAll') {
          this.listValue.map(d => d.deselect());
        } else {
          selectedValue.deselect();
        }
      }
    } else {
      //reset values
      this.listValue.map(d => d.deselect());
      //mark as selected the selected value
      selectedValue.select();
    }

    const selectedListValues = this.listValue.filter(d => !!d.selected).map(d => d.id)
    
    if (this.control.auxControl) {
      this.control.auxControl.patchValue([...selectedListValues]);
    } else {
      this.control.patchValue([...selectedListValues]);
    }
  }

  handleDropdownDefaultValues(): void {
    const selectedListValues = this.listValue.filter(d => !!d.selected).map(d => d.id)
    
    if (this.control.auxControl) {
      this.control.auxControl.patchValue([...selectedListValues]);
    } else {
      this.control.patchValue([...selectedListValues]);
    }
  }

  get selectedListValueNames(): string {
    const selectedListValues = this.listValue.filter(d => d.selected);

    if (selectedListValues.length === 0) {
      return this.defaultValue?.name ?? this.control.label;
    }

    const listValueNames = selectedListValues.map(d => d.name);

    return listValueNames.length ? listValueNames.join(', ') : ''
  }
}
