import { Message } from "./message.model";
import { SessionListDto, SessionResponseDto } from "./session-response.dto";

export class SessionResponse {
  id: number;
  createdAt: Date;
  systemContext: string;
  messages: Message[];

  constructor(session: SessionResponseDto) {
    this.id = session.id;
    this.createdAt = session.created_at;
    this.systemContext = session.messages.find(m => m.role === 'system')?.content || '';
    this.messages = session.messages.filter(m => m.role !== 'system').map(m => new Message(m));
  }
}

export class SessionList {
  id: number;
  gptModel: string;
  systemContext: string;

  constructor(sessionList: SessionListDto) {
    this.id = sessionList.id;
    this.gptModel = sessionList.gpt_model;
    this.systemContext = sessionList.system_context;
  }
}
