import { AddressDto } from "./address.dto";

export class Address {
  street: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  fullAddress?: string;

  constructor(address: AddressDto) {
    this.street = address.street;
    this.city = address.city;
    this.state = address.state;
    this.zipCode = address.zipcode;
    this.country = address.country;
    this.fullAddress = `${this.street} ${this.city} ${this.state}, ${this.zipCode}`;
    
  }
}
