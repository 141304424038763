import { BaseDto } from "./base.dto";

export class Base {
  id: number;
  uid: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  isActive: boolean;
  createdBy: string;
  updatedBy: string;
  deletedBy: string;

  constructor(data: BaseDto) {
    this.id = data.id;
    this.uid = data.uid;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    this.deletedAt = data.deleted_at;
    this.isActive = data.is_active;
    this.createdBy = data.created_by;
    this.updatedBy = data.updated_by;
    this.deletedBy = data.deleted_by;
  }
}
