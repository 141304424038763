<div class="modal-header">
  <h4 class="2-50 modal-title" id="modal-title">Create new chat</h4>
  <div class="w-50 text-end">
    <span style="font-size: 20px; cursor: pointer;" (click)="modal.dismiss('Close')">
      <img src='assets/media/icons/duotune/general/gen034.svg'>
    </span>
  </div>
</div>

<div class="modal-body">
  <form [formGroup]="systemContextForm">
    <div class="fv-row mb-10">
      <app-custom-input [control]="gptModel" [type]="inputTypes.DROPDOWN" [listValue]="gptModels"></app-custom-input>
      @if (gptModel.errors) {
        <div class="text-danger mt-2">
          @if (formSubmitted && gptModel.errors['required']) {
            <span>Please select the gpt model.</span>
          }
        </div>
      }
    </div>

    <div class="fv-row mb-10">
      <app-custom-input [control]="systemContext"></app-custom-input>
      @if (systemContext.errors) {
        <div class="text-danger mt-2">
          @if (formSubmitted && systemContext.errors['required']) {
            <span>Please input the system context.</span>
          }
        </div>
      }
    </div>

    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-primary" [disabled]="!systemContextForm.valid" (click)="create()">
        <!-- <span class="spinner-border spinner-border-sm mr-1"></span> -->
        Create
      </button>
    </div>
  </form>
</div>
