import { AccountProfileDto } from "./account-profile.dto";
import { Base } from "./base.model";
import { IAMUserDetailsDto } from "./iam-user-details.dto";
import { IAMUserDetails } from "./iam-user-details.model";

export class AccountProfile extends Base {
  userName: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  theme: string;
  loginCount: number;
  refreshCount: number;
  referralCode: string;
  referredBy: number;
  userDetails: IAMUserDetails

  constructor(profile: AccountProfileDto, userDetails: IAMUserDetailsDto) {
    super(profile);
    this.userDetails = new IAMUserDetails(userDetails);
    this.userName = profile.username;
    this.firstName = profile.first_name;
    this.lastName = profile.last_name;
    this.fullName = `${this.firstName} ${this.lastName}`;
    this.email = profile.email;
    this.theme = profile.theme;
    this.loginCount = profile.login_count;
    this.refreshCount = profile.refresh_count;
    this.referralCode = profile.referral_code;
    this.referredBy = profile.referred_by;
  }
}

export type UserDetailsType = AccountProfile | undefined;
