export enum Urls {
  APPS = 'apps',
  CHAT = 'chat',
  AI_CHAT = 'ai-chat',
  HOME = 'home',
  GAME_HISTORY = 'game-history',
  CREATE_GAME = 'create-game',
  PLAYGROUND = 'playground',
  LIVE_GAME = 'live-game',
  FEEDBACK = 'feedback',
}
