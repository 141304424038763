import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from } from 'rxjs';
import { MODALS } from 'src/app/_constants/Modals';
import { UserDetailsType } from '../../models/account-profile.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-profile-uploader',
  templateUrl: './profile-uploader.component.html',
  styleUrl: './profile-uploader.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProfileUploaderComponent
    }
  ]
})
export class ProfileUploaderComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() set defaultValue(value: string) {
    this.file = value;
    this.defaultImage = value;
  }
  @Input() height: string = '200px';
  @Input() width: string = '200px';

  user$: Observable<UserDetailsType>;
  file: string = '';
  selectedFile: any;
  updateFileUrl: any;
  defaultImage: string;

  constructor(private modalsService: NgbModal, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.defaultImage) {
      this.file = this.defaultImage;
    }
  }

  onChange = (fileUrl: string | null) => {};
  onTouched = () => {};
  disabled: boolean = false;

  writeValue(_file: string): void {
    this.file = _file;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onFileChange(event: any): void {
    const files = event.target.files as FileList;
    if (files.length > 0) {
      this.selectedFile = files[0];
      const _file = URL.createObjectURL(files[0]);
      this.file = _file;
      this.resetInput();

      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = _file;
      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);
        const base64data = canvas.toDataURL();
        this.updateFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(base64data);
        this.onChange(base64data);
      }
      // this.openImageCropper(_file).subscribe({
      //   next: (result: any) => {
      //     if (result !== 'close') {
      //       this.file = result;
      //       this.ref.detectChanges();
      //       this.onChange(this.file);
      //     }
      //   }
      // });
    }
  }

  openImageCropper(image: string): Observable<any> {
    const modalRef = this.modalsService.open(MODALS['imageCropper'], { backdrop: 'static' });
    modalRef.componentInstance.image = image;
    return from(modalRef.result);
  }

  removePhoto(evt: any): void {
    evt.preventDefault();
    this.onChange(null);
    this.file = '';
  }

  resetInput(): void {
    const input = document.getElementById('') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }
}
