import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PaginatedResponse } from '../_shared/models/generic-object.dto';
import { Observable, tap } from 'rxjs';
import { InvestmentInterestsDto } from '../_shared/models/investment-interests.dto';
import { MultiSelectList } from '../_shared/models/multi-select-list.model';

@Injectable({
  providedIn: 'root'
})
export class InvestmentInterestsService {
  private readonly apiUrlV1;
  private readonly investmentInterestsUrl = 'iam/investmentinterests';

  private _investmentInterests: MultiSelectList[] = [];
  
  constructor(private httpClient: HttpClient) { 
    this.apiUrlV1 = environment.apiUrlV1;
  }

  getInvestmentInterests(): Observable<PaginatedResponse<InvestmentInterestsDto>> {
    return this.httpClient.get<PaginatedResponse<InvestmentInterestsDto>>(`${this.apiUrlV1}/${this.investmentInterestsUrl}/`).pipe(
      tap((investmentInterestsResponse) => {
        this._investmentInterests = investmentInterestsResponse.results.map(r => new MultiSelectList(r.name, r.name));
      })
    );
  }

  get investmentInterests(): MultiSelectList[] {
    return this._investmentInterests;
  }
}
