import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionListDto, SessionResponseDto } from '../_shared/models/session-response.dto';
import { ChatSessionsDto } from '../_shared/models/chat-sessions.dto';
import { MessageResponseDto } from '../_shared/models/message.dto';

@Injectable({
  providedIn: 'root'
})
export class AiChatService {

  private readonly apiUrl;
  private readonly aiChatUrl: string = '/internal/ai-chat';

  constructor(private httpClient: HttpClient) { 
    this.apiUrl = environment.apiStudioUrl;
  }

  getGptModels(): Observable<[]> {
    return this.httpClient.get<[]>(`${this.apiUrl}${this.aiChatUrl}/gpt-models/`);
  }

  startSessionChat(session: {system_context: string, gpt_model: string}): Observable<SessionResponseDto> {
    return this.httpClient.post<SessionResponseDto>(`${this.apiUrl}${this.aiChatUrl}/start/`, session);
  }

  continueSessionChat(sessionId: number, prompt: string): Observable<MessageResponseDto> {
    return this.httpClient.post<MessageResponseDto>(`${this.apiUrl}${this.aiChatUrl}/chat/${sessionId}/`, { prompt });
  }

  getChatSessions(): Observable<ChatSessionsDto[]> {
    return this.httpClient.get<ChatSessionsDto[]>(`${this.apiUrl}${this.aiChatUrl}/sessions/`)
  }

  getChatSession(sessionId: number): Observable<SessionResponseDto> {
    return this.httpClient.get<SessionResponseDto>(`${this.apiUrl}${this.aiChatUrl}/${sessionId}/`);
  }
}
