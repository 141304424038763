import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/services/auth.service';
import { environment } from 'src/environments/environment';
// #fake-start#
import { FakeAPIService } from './_fake/fake-api.service';
import { JWTInterceptor } from './_helpers/interceptors/jwt.interceptor';
import { mergeMap } from 'rxjs';
import { ErrorInterceptor } from './_helpers/interceptors/error.interceptor';
import { SharedModule2 } from './_shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { InvestmentInterestsService } from './services/investment-interests.service';
// #fake-end#

function appInitializer(authService: AuthService, investmentInterestsService: InvestmentInterestsService) {
  return () => 
    //@ts-ignore
  authService.verifiyToken().pipe(
    mergeMap(() => authService.refreshToken()),
    mergeMap(() => authService.getUser())
  );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    // #fake-start#
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
          passThruUnknownUrl: true,
          dataEncapsulation: false,
        })
      : [],
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    SharedModule2,
    ToastrModule.forRoot()
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService, InvestmentInterestsService],
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
