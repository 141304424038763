import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, EMPTY, combineLatest } from 'rxjs';
import { map, catchError, switchMap, finalize, tap } from 'rxjs/operators';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TokenDto } from 'src/app/_shared/models/token.dto';
import StorageHelper from 'src/app/_helpers/storage.helper';
import { PersonalDetailsDto } from 'src/app/_shared/models/payloads/registration/personal-details.dto';
import { ProfessionalDetailsDto } from 'src/app/_shared/models/payloads/registration/professional-details.dto';
import { RegistrationDetailsDto } from 'src/app/_shared/models/payloads/registration/registration-details.dto';
import { AccountService } from 'src/app/services/account.service';
import { IAMUserDetails } from 'src/app/_shared/models/iam-user-details.model';
import { IAMUserDetailsDto } from 'src/app/_shared/models/iam-user-details.dto';
import { AccountProfile, UserDetailsType } from 'src/app/_shared/models/account-profile.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private readonly apiStudioUrl;
  private readonly apiUrlV1;
  private readonly tokenUrl: string = 'token';
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserDetailsType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserDetailsType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserDetailsType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserDetailsType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private httpClient: HttpClient,
    private accountService: AccountService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserDetailsType>(undefined);
    this.apiStudioUrl = environment.apiStudioUrl;
    this.apiUrlV1 = environment.apiUrlV1;
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    
  }

  // public methods
  login(username: string, password: string): Observable<UserDetailsType> {
    this.isLoadingSubject.next(true);
    return this.httpClient.post<TokenDto>(`${this.apiUrlV1}/${this.tokenUrl}/`, {username, password}).pipe(
      map((auth: TokenDto) => {
        const result = StorageHelper.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUser()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(['/auth/login']);
  }

  getUser(): Observable<UserDetailsType> {
    this.isLoadingSubject.next(true);
    return this.accountService.getUserDetailsAndProfile().pipe(
      map((accountDetails) => {
        this.currentUserSubject.next(accountDetails);
        return accountDetails;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  // need create new user then login
  registration(accountRegistration: RegistrationDetailsDto): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.httpClient.post<any>(`${this.apiUrlV1}/iam/accounts/register/`, accountRegistration).pipe(
      tap(() => this.isLoadingSubject.next(false))
    );
  }

  uploadProfilePhoto(photo: File) {
    const formData: FormData = new FormData();
    formData.append('profile_picture', photo, photo.name);
    formData.append('user_type', this.currentUserValue?.userDetails.userType.toString() ?? 'DEV');
    return this.httpClient.put(`${this.apiUrlV1}/iam/iamuserdetails/me/`, formData).pipe(
      tap(() => this.getUser())
    );
  }

  updateProfieDetails(payload: IAMUserDetailsDto) {
    return this.httpClient.put(`${this.apiUrlV1}/iam/iamuserdetails/me/`, payload).pipe(
      tap(() => this.getUser())
    );
  }

  createPersonalDetails(personalDetails: PersonalDetailsDto): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrlV1}/iam/accounts/register/`, personalDetails);
  }

  createProfessionDetails(professionalDetails: ProfessionalDetailsDto): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrlV1}/iam/iamuserdetails/`, professionalDetails);
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  verifiyToken() {
    const url = new URL(window.location.href);
    const tokenFromUrl = decodeURIComponent(url.searchParams.get('auth') ?? '');
    const token = StorageHelper.getAuthFromLocalStorage();
    const accessToken = token?.access || tokenFromUrl;

    if (accessToken) {
      return this.httpClient.post<TokenDto>(
        `${this.apiUrlV1}/${this.tokenUrl}/`, 
        null, 
        { 
          headers: {
          'Authorization': 'Bearer ' + tokenFromUrl
          } 
      })
      .pipe(
        map(token => {
          StorageHelper.setAuthFromLocalStorage(token)
          return token;
        })
      );
    }
    return EMPTY;
  }

  refreshToken(): Observable<TokenDto> {
    const token = StorageHelper.getAuthFromLocalStorage();
    if (token) {
      return this.httpClient.post<TokenDto>(`${this.apiUrlV1}/${this.tokenUrl}/refresh/`, { refresh: token.refresh })
      .pipe(
        map(token => {
          StorageHelper.setAuthFromLocalStorage(token)
          return token;
        })
      );
    }
    return EMPTY;
  }
  
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
