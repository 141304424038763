import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Urls } from 'src/app/_constants/Urls';
import StorageHelper from 'src/app/_helpers/storage.helper';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {

  constructor(private router: Router) {}

  canActivate() {
    const isLoggedIn = !!StorageHelper.getAuthFromLocalStorage();
    if (isLoggedIn) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    // this.document.location.href = `${environment.aiSquareStudioUrl}/auth/login`;
    this.router.navigate([`${Urls.APPS}/${Urls.HOME}`]);
    return false;
  }
}
