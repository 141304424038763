import { AccountTypes } from "src/app/_constants/AccountTypes";
import { Address } from "./address.model";
import { IAMUserDetailsBaseDto, IAMUserDetailsDto } from "./iam-user-details.dto";

export class IAMUserDetailsBase {
  bio: string;
  address: Address;
  phone: string;
  dateOfBirth?: string;
  userType: string;
  linkedInUrl: string;
  githubUrl: string;
  twitterUrl: string;
  facebookUrl: string;
  websiteUrl: string;
  skills?: string[];
  companyInfo: string;
  investmentInterests?: string[];
  technicalInterests?: string[];
  communicationPreferencesPhone: boolean;
  communicationPreferencesEmail: boolean;

  constructor(userDetails: IAMUserDetailsBaseDto) {
    this.bio = userDetails.bio;
    this.address = new Address(userDetails.address);
    this.phone = userDetails.phone;
    this.dateOfBirth = userDetails.dob;
    this.linkedInUrl = userDetails.linkedin_url;
    this.githubUrl = userDetails.github_url;
    this.twitterUrl = userDetails.twitter_url;
    this.facebookUrl = userDetails.facebook_url;
    this.websiteUrl = userDetails.website_url;
    this.skills = userDetails.skills;
    this.companyInfo = userDetails.company_info;
    this.investmentInterests = userDetails.investment_interests;
    this.technicalInterests = userDetails.technical_interests_data;
    this.communicationPreferencesEmail = userDetails.communication_preferences_email;
    this.communicationPreferencesPhone = userDetails.communication_preferences_phone;

    switch(userDetails.user_type) {
      case 'DEV':
        this.userType = 'DEV';
        break;
      case 'REC':
        this.userType = 'REC';
        break;
      case 'INV':
        this.userType = 'INV';
        break;
    }
  }
}

export class IAMUserDetails extends IAMUserDetailsBase {
  profilePhoto?: string;

  constructor(userDetails: IAMUserDetailsDto) {
    super(userDetails);
    this.profilePhoto = userDetails.profile_picture;
  }
}
