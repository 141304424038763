@switch (type) {
  @case (inputTypes.TEXT) {
    <label class="form-label {{control.required ? 'required': ''}}" *ngIf="withLabel">{{ control.label }}</label>
    <input [type]="inputType" class="form-control input-item form-control-lg form-control-solid" [placeholder]="control.placeholder" [formControl]="control.auxControl ? control.auxControl : control">
  }
  @case (inputTypes.TEXTAREA) {
    <label class="form-label {{control.required ? 'required': ''}}" *ngIf="withLabel">{{ control.label }}</label>
    <textarea class="form-control form-control-lg form-control-solid" type="text" rows="5" [formControl]="control.auxControl ? control.auxControl : control"></textarea>
  }
  @case (inputTypes.DROPDOWN) {
    <label class="form-label {{control.required ? 'required': ''}}"  *ngIf="withLabel">{{ control.label }}</label>
    <div ngbDropdown class="w-100">
      <button type="button" class="btn btn-outline-primary w-100 d-flex justify-content-between align-items-center text-truncate custom-dropdown" ngbDropdownToggle>
        {{selectedListValueNames}}
      </button>
      @if (multiSelect) {
        <div ngbDropdownMenu class="w-100 p-3" style="height: 200px;overflow-x: auto;">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="searchText" placeholder="Enter text to search">
          <mat-checkbox class="w-100" (change)="onDropdownChange($event, 'selectAll')">Select All</mat-checkbox>
          @for (value of listValue | filterText: searchText; track $index) {
            <mat-checkbox class="w-100" (change)="onDropdownChange($event, value)" [checked]="value.selected">{{value.name ?? 'N/A'}}</mat-checkbox>
          }
        </div>
      } @else {
        <div ngbDropdownMenu class="w-100" style="height: 200px;overflow-x: auto;">
          @for (value of listValue; track $index) {
            <button ngbDropdownItem (click)="onDropdownChange($event, value, false)">{{value.name ?? 'N/A'}}</button>
          }
        </div>
      }
    </div>
  }
  @case (inputTypes.SLIDER) {
    <label class="form-label d-flex justify-content-between {{control.required ? 'required': ''}}"  *ngIf="withLabel">
      <span>{{control.label}}</span>
      <span>{{control.value}}</span>
    </label>
    @if (control.placeholder) {
      <label class="small" style="color: var(--text-color-accent);">First player to reach this points wins the game.</label>
    }
    <mat-slider class="w-100" [min]="sliderMin" [step]="sliderStep" [max]="sliderMax" style="left: -5px;">
      <input matSliderThumb [formControl]="control.auxControl ? control.auxControl : control">
    </mat-slider>
  }
  @case (inputTypes.DATEPICKER) {
    <label class="form-label {{control.required ? 'required': ''}}"  *ngIf="withLabel">{{ control.label }}</label>
    <div class="input-group">
      <input
        class="form-control form-control-lg form-control-solid"
        placeholder="yyyy-mm-dd"
        name="dp"
        [formControl]="control.auxControl ? control.auxControl : control"
        ngbDatepicker
        [placeholder]="control.placeholder"
        #d="ngbDatepicker"
      />
      <button class="btn btn-outline-secondary bi bi-calendar3 date-icon" (click)="d.toggle()" type="button"></button>
    </div>
  }
  @case (inputTypes.SWITCH) {
    <input class="form-check-input" type="checkbox" role="switch" [formControl]="control.auxControl ? control.auxControl : control">
    <label class="form-label" for="flexSwitchCheckChecked"  *ngIf="withLabel">{{ control.label }}</label>
  }
  @case (inputTypes.CHIPS) {
    <label class="form-label {{control.required ? 'required': ''}}"  *ngIf="withLabel">{{ control.label }}</label>
    <input 
      class="form-control input-item form-control-lg form-control-solid"
      [formControl]="control.auxControl ? control.auxControl : control" 
      [typeahead]="chipListsItem" 
      [typeaheadMinLength]="0" 
      [typeaheadMultipleSearch]="true"
      [typeaheadLatinize]="false"
      typeaheadMultipleSearchDelimiters=",">
  }
}
