import { environment } from "src/environments/environment";
import { TokenDto } from "../_shared/models/token.dto";
import { AccountRegistrationDto } from "../_shared/models/account-registration.dto";

export default class StorageHelper {
  static authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private static readonly multipStepRegistrationKey = `${environment.appVersion}-registration`
  // private methods
  static setAuthFromLocalStorage(auth: TokenDto): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  static getAuthFromLocalStorage(): TokenDto | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }
      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  static saveRegistrationInfo(registrationInfo: AccountRegistrationDto): void {
    window.localStorage[this.multipStepRegistrationKey] = JSON.stringify(registrationInfo);
  }
  
  static getRegistrationInfo(): string | null {
    return window.localStorage.getItem(this.multipStepRegistrationKey);
  }

  static saveData(key: string, value: string): void {
    window.localStorage.setItem(key, value);
  }

  static getData(key: string): string | null {
    return window.localStorage.getItem(key);
  }
}
