export class MultiSelectList {
  id: string;
  name: string;
  selected?: boolean;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }

  select(): void {
    this.selected = true;
  }

  deselect(): void {
    this.selected = false;
  }
}
