import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomControl } from '../../models/custom-form.model';
import { INPUT_TYPE } from 'src/app/_constants/InputTypes';
import { MultiSelectList } from '../../models/multi-select-list.model';
import { AiChatService } from 'src/app/services/ai-chat.service';
import { Subscription } from 'rxjs';
import { SessionResponse } from '../../models/session-response.model';

@Component({
  selector: 'app-system-context.dialog',
  templateUrl: './system-context.dialog.component.html',
  styleUrl: './system-context.dialog.component.scss'
})
export class SystemContextDialogComponent implements OnInit, OnDestroy {

  systemContextForm: FormGroup;
  inputTypes = INPUT_TYPE;
  gptModels: MultiSelectList[] = [];
  private unsubscribe: Subscription[] = [];
  formSubmitted: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private aiChatService: AiChatService,
  ) {}

  ngOnInit(): void {
    const gptModels$ = this.aiChatService.getGptModels().subscribe({
      next: (gptModelsResponse) => {
        this.gptModels = gptModelsResponse.map(m => new MultiSelectList(m, m));
      }
    });
    this.unsubscribe.push(gptModels$)

    this.systemContextForm = new FormGroup({
      gptModel: new CustomControl({
        label: 'Select GPT model',
        validation: [Validators.required]
      }),
      systemContext: new CustomControl({
        label: 'System context',
        placeholder: 'This will appear as the main title of your chat.',
        validation: [Validators.required]
      })
    })
  }

  create(): void {
    const startChatPayload = {
      system_context: this.systemContext.value,
      gpt_model: Array.isArray(this.gptModel.value) ? this.gptModel.value[0] : this.gptModel.value
    }

    const startSession$ = this.aiChatService.startSessionChat(startChatPayload).subscribe({
      next: (response) => {
        const sessionData = new SessionResponse(response);
        this.modal.close({
          session: sessionData,
          gptModel: startChatPayload.gpt_model
        })
      }
    })
    this.unsubscribe.push(startSession$);
    
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach(u => u.unsubscribe());
  }

  get f(): { [key: string]: AbstractControl } {
    return this.systemContextForm.controls;
  }

  get gptModel(): CustomControl {
    return this.f['gptModel'] as CustomControl;
  }

  get systemContext(): CustomControl {
    return this.f['systemContext'] as CustomControl;
  }
}
