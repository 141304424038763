import { MessageResponseDto } from "./message.dto";

export class Message {
  user: number;
  type: 'in' | 'out';
  text: string;
  date: Date;
  template?: boolean;

  constructor(message: MessageResponseDto) {
    // user 0 as the loggedin user and 1 as the system
    this.user = message.role === 'user' ? 0 : 1;
    this.type = message.role === 'user' ? 'out' : 'in';
    this.text = message.content;
    this.date = message.created_at;
  }
}
