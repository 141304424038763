import { NgModule } from "@angular/core";
import { ProfileUploaderComponent } from "./components/profile-uploader/profile-uploader.component";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../_internal/shared/shared.module";
import { ImageCropperDialogComponent } from './dialogs/image-cropper-dialog/image-cropper-dialog.component';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSliderModule } from "@angular/material/slider";
import { NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormatDatePipe } from "./pipes/format-date.pipe";
import { SystemContextDialogComponent } from './dialogs/system-context.dialog/system-context.dialog.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FilterTextPipe } from "./pipes/filter-text.pipe";

@NgModule({
  declarations: [
    ProfileUploaderComponent, 
    ImageCropperDialogComponent,
    CustomInputComponent,
    FormatDatePipe,
    SystemContextDialogComponent
  ],
  exports: [
    ProfileUploaderComponent, 
    ImageCropperDialogComponent,
    CustomInputComponent,
    FormatDatePipe,
    SystemContextDialogComponent,
    TypeaheadModule,
    FilterTextPipe
  ],
  imports: [
    CommonModule, 
    SharedModule,
    MatCheckboxModule,
    MatSliderModule,
    NgbDropdownModule,
    FormsModule, 
    ReactiveFormsModule,
    NgbDatepickerModule,
    TypeaheadModule,
    FilterTextPipe
  ]
})
export class SharedModule2 {}
