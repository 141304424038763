import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "src/app/modules/auth";
import StorageHelper from "../storage.helper";
import { environment } from "src/environments/environment";

@Injectable()
export class JWTInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = StorageHelper.getAuthFromLocalStorage();
    const isLoggedIn = !!token;
    const isApiUrl = req.url.startsWith(environment.apiUrlV1) || req.url.startsWith(environment.apiStudioUrl) ;
    if (isLoggedIn && isApiUrl) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token.access}`
        }
      });
    }

    return next.handle(req);
  }
}